import { useState } from "react";

function submitForm(e, setSubmitted) {

    console.log('### submitted', setSubmitted);

    e.preventDefault();

    let myForm = document.getElementById("domainOffer");
    let formData = new FormData(myForm);

console.log(formData);

    fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams(formData).toString(),
    })
        .then(() => { setSubmitted(true); })
        .catch((error) => alert(error));
    
}

export default function ContactForm() {

    const [ submitted, setSubmitted ] = useState(false);

    if(submitted) {
        return <div className="notification is-primary is-light">
            <strong>Thank you for contacting us!</strong> We will respond shortly.
        </div>
    }

    return <form 
        id="domainOffer"
        data-netlify="true" 
        netlify-honeypot="email-field"
        name="domainOffer" 
        method="POST" 
        onSubmit={e => submitForm(e, setSubmitted)}>
        <p class="is-hidden"><input name="email-field" /></p>
        <input type="hidden" name="form-name" value="domainOffer" />
        <div className="field">
            <label className="label">Your Name</label>
            <div className="control">
            <input className="input" type="text" placeholder="" name="from" />
            </div>
        </div>
        
        <div className="field">
            <label className="label">Email</label>
            <div className="control">
                <input className="input" type="email" placeholder="hello@mail.com" name="email"/>
            </div>
        </div>
        
        <div className="field">
            <label className="label">Phone Number</label>
            <div className="control">
                <input className="input" type="tel" placeholder="(888) 888-8888" name="phone" />
            </div>
        </div>

        <div className="field">
            <label className="label">Offer (USD)</label>
            <div className="control">
            <input className="input" type="text" placeholder="$" name="offer-amount"/>
            <p className="help">Submit an offer with your inquiry if you are interested in purchasing this domain</p>
            </div>
        </div>
        
        <div className="field">
            <label className="label">Additional Comments</label>
            <div className="control">
            <textarea className="textarea" name="additional-comments"></textarea>
            </div>
        </div>
        
        <div className="field is-grouped">
            <div className="control">
            <button className="button is-primary" type='submit'>Submit</button>
            </div>
        </div>
        </form>;
}