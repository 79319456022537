import logo from './logo.svg';
import ContactForm from './ContactForm';

function App() {

  const { hostname } = window.location;
  const domain = hostname.replace('www.', '');

  return (
    <div>
      <section className="hero is-primary main-hero mb-5">
        <div className="hero-body has-text-centered">
          <p className="subtitle is-7">
            Great News!
          </p>
          <p className="title is-2">
            { domain }
          </p>
          <p className="subtitle is-5">
            Is available for purchase!
          </p>
          <div className="columns">
            <div className="column is-6 is-offset-3 is-flex is-justify-content-center is-align-content-center is-align-items-center">
              <div className="card card-price">
                <div className="card-content">
                  <div className="content">
                    <div className="title is-2">{ domain.indexOf('interiors') > -1 ? '$13,500' : '$14,200' }</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    <div className='container is-fluid'>
      <div className="columns">
        <div className="column is-6 is-offset-3">
          <div className="title is-5">Contact us using the form below to inquire.</div>
          <div className="subtitle is-6">We will respond in 24 hours or less.</div>
          <ContactForm />
        </div>
      </div>
    </div>
    </div>
  );
}

export default App;
